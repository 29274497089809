#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app-body {
  flex-grow: 1
}

/* WaveQualityFilter Page */

.quality-filter span.MuiSlider-markLabel {
  font-size: 0.8rem;
  margin-top: 1rem
}

.quality-filter span.MuiSlider-mark, .quality-filter span.MuiSlider-markActive {
  width: 1px;
  height: 3px;
  background-color: #fff;
}

/* AddSpot component Page */

.slider-input span.MuiSlider-markLabel {
  font-size: 0.6rem;
  margin-top: 0rem
}

.slider-input span.MuiSlider-mark, .slider-input span.MuiSlider-markActive {
  width: 1px;
  height: 3px;
  background-color: #fff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
